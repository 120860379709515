/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-01-13",
    versionChannel: "nightly",
    buildDate: "2023-01-13T00:15:41.939Z",
    commitHash: "2e7201334d899452d43ed80f6d7adb751ec7841c",
};
